<template>
  <div class="myIdCardContainer">
    <van-nav-bar :title="$t('myIdCard.mcTxt1')"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true">
      <template #right>
        <van-icon @click="enterAddCardEvent"
                  name="plus"
                  color="#ffffff"
                  size="18" />
      </template>
    </van-nav-bar>

    <div class="fixedH"
         style="height: 1.22667rem"></div>
    <div class="myIdCardBox">
      <div class="myIdCardBg"
           v-for="(val,key) in list"
           :key="key"
           @click="visitList(val,key)">
        <div class="leftIconBox">
          <div class="leftIconBg">
            <svg-icon class="idCardIcon"
                      icon-class="idCardSvg" />
          </div>
        </div>
        <div class="rightInfoBox">
          <h3 class="infoTit">{{ val.certifiname }}</h3>
          <p class="infoNumber">{{ val.certifino }}</p>
          <p class="infoDate">{{ val.senddate }} - {{ val.validdate }}</p>
        </div>
      </div>
    </div>
    <div class="fixedIdCardBox"
         v-show="showFixed">
      <div class="fixedIdCardIcon"
           @click="enterAddCardEvent">
        <svg-icon class="addIdCard"
                  icon-class="addIdCard" />
      </div>
    </div>
  </div>
</template>
<script>
import { getMyCertList } from "@api/wx.js";
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
export default {
  data () {
    return {
      userInfo,
      list: [],
      showFixed: false
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    onClickLeft () {
      this.$router.push({
        path: "/personCen"
      });
    },
    onClickRight () {

    },
    enterAddCardEvent () {
      this.$router.push({
        path: "/addMyIdCard"
      });
    },
    getData () {
      let params = {
        empid: this.userInfo.empid
      }
      getMyCertList(params).then(res => {
        console.log(res)
        this.list = res.data;
        this.showFixed = true;
      })
    },
    visitList (val, index) {
      this.$router.push({
        path: '/myIdCardDetails',
        query: { autoid: val.autoid, ischeck: val.ischeck }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.myIdCardContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .myIdCardBox {
    width: 100%;
    margin-top: 15px;
    .myIdCardBg {
      width: 726px;
      height: 278px;
      max-width: 100%;
      background: url("~@/assets/img/myIdCardBg.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      display: flex;
    }
    .leftIconBox {
      width: 182px;
      min-width: 182px;
      padding-left: 64px;
      padding-top: 56px;
      .leftIconBg {
        width: 88px;
        height: 88px;
        background: #fff;
        border-radius: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .idCardIcon {
        width: 54.52px;
        height: 43.04px;
      }
    }
    .rightInfoBox {
      width: 100%;
      position: relative;
      padding-right: 25px;
      .infoTit {
        margin: 0;
        font-size: 36px;
        margin-top: 44px;
        color: #fff;
        font-weight: bold;
        line-height: 1;
      }
      .infoNumber {
        margin: 0;
        margin-top: 16px;
        font-size: 30px;
        line-height: 1;
        color: #fff;
      }
      .infoDate {
        margin: 0;
        line-height: 1;
        color: #fff;
        font-size: 24px;
        position: absolute;
        right: 44px;
        bottom: 50px;
      }
    }
  }
  .fixedIdCardBox {
    width: 100%;
    height: 110px;
    margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    z-index: 999;
    .fixedIdCardIcon {
      width: 110px;
      height: 110px;
      border-radius: 110px;
      background: #fff;
      box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      .addIdCard {
        width: 56px;
        height: 56px;
      }
    }
  }
}
</style>